<div mat-dialog-title class="fx-layout-row">
  <div class="fx-layout-row fx-flex fx-align--x-center fx-align--space-between-x">
    <h2>
      {{ data.title }}
    </h2>
    <button mat-icon-button mat-dialog-close *ngIf="data.closeMethod == 'x'" tabindex="-1">
      <mat-icon svgIcon="x"></mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content [innerHtml]="data.message" [ngClass]="{'no-actions': data.closeMethod !== 'button' && !data.yesButtonText}">

</mat-dialog-content>

<mat-dialog-actions *ngIf="data.closeMethod == 'button' || data.yesButtonText" [ngClass]="{'single-button': data.yesButtonText && !data.noButtonText}"
                    class="fx-layout-row fx-align--space-between-x">

  <mat-checkbox *ngIf="data.yesVerifierText" [(ngModel)]="yesVerified">
    {{ data.yesVerifierText }}
  </mat-checkbox>
  <button mat-flat-button [color]="data?.primaryButtonColor ? data.primaryButtonColor : 'primary'" [mat-dialog-close]="!data.yesVerifierText || yesVerified">
    {{ data.yesButtonText }}
  </button>

  <button mat-flat-button [color]="data?.secondaryButtonColor ? data.secondaryButtonColor : 'secondary'" [mat-dialog-close]="false" *ngIf="data.noButtonText">
    {{ data.noButtonText }}
  </button>
</mat-dialog-actions>
